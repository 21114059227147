import firebase from "firebase";

// Initialize Firebase
const config = {
  
  databaseURL: "https://educacionmedicacontinua.firebaseio.com",
  apiKey: "AIzaSyA8jG54ub_Q3yPrdYkTqitxROhDfVTmvgw",
  authDomain: "educacionmedicacontinua-cat.firebaseapp.com",
  projectId: "educacionmedicacontinua-cat",
  storageBucket: "educacionmedicacontinua-cat.appspot.com",
  messagingSenderId: "106578103634",
  appId: "1:106578103634:web:571d08d6123e447c657e8b",
  measurementId: "G-MVTBPWTD9K"
};
firebase.initializeApp(config);
firebase.auth().languageCode = 'es'
const auth = firebase.auth();
const functions = firebase.functions();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.firestore();

export {
  database,
  auth,
  functions,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};
