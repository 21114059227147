import React from "react";
import {Button} from "antd";
import StarRatingComponent from "react-star-rating-component";

import IntlMessages from "util/IntlMessages";

const ProductItem = ({product, grid}) => {
  //console.log(product)
  const { cover, book_file, link, book_link, file, video_file, title} = product;
  return (
    <div className={`gx-product-item  ${grid ? 'gx-product-vertical' : 'gx-product-horizontal'}`}>
      <div className="gx-product-image">
        <div className="gx-grid-thumb-equal">
          <span className="gx-link gx-grid-thumb-cover">
            <img alt="Remy Sharp" src={cover}/>
          </span>
        </div>
      </div>

      <div className="gx-product-body">
        <h3 className="gx-product-title">{title}
        </h3>
      </div>

      <div className="gx-product-footer">
        {(book_file && book_file != "")?
          <Button href={book_file} type="primary" target="_blank">Ver contenido</Button>:null}
          {(book_link && book_link != "")?
          <Button href={book_link} type="primary" target="_blank">Ver contenido.</Button>:null}
      </div>
      <div className="gx-product-footer">

      {(file && file != "") ?
          <Button href={file} type="primary" target="_blank">Ver Video</Button> : null}
          {(link && link != "") ?
          <Button href={link} type="primary" target="_blank">Ver Video.</Button> : null}
    </div>
    </div>
  )
};

export default ProductItem;

